import React from 'react';
import { graphql } from 'gatsby';
import 'moment/locale/es';
import 'moment/locale/pt';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PagerComponent from '../components/PagerComponent';

export const query = graphql`
 query Pages($id: String) {
    prismic {
      allProject_listing_pages(id: $id) {
        edges {
          node {
            description
            title
            _meta {
              lang
            }
          }
        }
      } 
    }
  }
`;

const Projects = ({ data, location }) => {
  const page = data.prismic.allProject_listing_pages.edges[0];

  if (!page || !page.node) { return null; }

  const {
    title,
    description,
    _meta: { lang },
  } = page.node;

  return (
    <Layout lang={lang} location={location}>
      <SEO title={title} description={description} />
      <PagerComponent initialSection="project_listing_page" lang={lang} />
    </Layout>
  );
};

export default Projects;
